/* General body styles */
body {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #f4f4f4, #e0e0e0);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transition: filter 0.3s ease-in-out;
}

/* Login container styles */
.login-container {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

/* Hover effect for login container */
.login-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Title styles */
.login-title {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
  animation: fadeIn 1s ease-in-out;
}

/* Form group styles */
.form-group {
  margin-bottom: 24px;
  position: relative;
}

/* Label styles */
.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #666;
  font-size: 18px;
  transition: color 0.3s ease-in-out;
}

/* Input styles */
.form-group input {
  width: 100%;
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-group input:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.3);
}

/* Error message styles */
.error-message {
  color: #d32f2f;
  margin-bottom: 12px;
  text-align: center;
  font-size: 16px;
  animation: fadeIn 1s ease-in-out;
}

/* Button styles */
.btn {
  background: linear-gradient(135deg, #4caf50, #45a049);
  color: white;
  border: none;
  cursor: pointer;
  padding: 14px;
  width: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Button hover effect */
.btn:hover {
  background: linear-gradient(135deg, #45a049, #4caf50);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Button active effect */
.btn:active {
  background: linear-gradient(135deg, #388e3c, #4caf50);
  transform: scale(0.98);
}

/* Loading overlay styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.3s ease-in-out;
  backdrop-filter: blur(10px);
  border-radius: 6px;
}


.loading-overlay.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Spinner styles */
.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #4caf50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Blur effect on the background */
body.blurred {
  filter: blur(10px);
}