/* General Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f0f4f8;
    color: #333;
    line-height: 1.6;
}

/* Labs Page Styles */
.labs-page {
    max-width: 900px;
    margin: 20px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.labs-page:hover {
    transform: scale(1.02);
}

.labs-page h2 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 700;
    animation: fadeIn 1s ease-in-out;
}

.lab-detail-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lab-status,
.iam-credentials {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.lab-status:hover,
.iam-credentials:hover {
    transform: scale(1.02);
}

.start-lab-btn,
.check-progress-btn {
    width: 100%;
    padding: 14px;
    border: none;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.start-lab-btn {
    background-color: #4CAF50;
}

.start-lab-btn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

.check-progress-btn {
    background-color: #007bff;
    margin-top: 10px;
}

.check-progress-btn:hover:not(:disabled) {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.check-progress-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Progress Popup Styles */
.progress-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.5s ease-in-out;
}

/* Status and Credentials Styles */
.progress-container {
    margin-top: 15px;
}

.progress-bar {
    height: 24px;
    background-color: #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress-bar-fill {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.5s ease-in-out;
}

.progress-text {
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    color: #555;
}

.instructions {
    margin-top: 20px;
}

.instructions-list {
    padding-left: 20px;
}

.lab-completed {
    text-align: center;
    padding: 20px;
    background-color: #d4edda;
    border-radius: 12px;
    color: #155724;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: bounceIn 1s ease-in-out;
}

.lab-completed .icon {
    font-size: 48px;
    margin-bottom: 10px;
}

.iam-credentials {
    background-color: #e7f3fe;
    border: 1px solid #b6d4fe;
}

.iam-credentials h3 {
    color: #084298;
    margin-bottom: 15px;
}

/* Error Message Styles */
.error-message {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px;
    font-size: 16px;
}

/* Back Button Styles */
.back-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    background-color: #007bff;
    color: #ffffff;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 16px;
    text-align: center;
}

.back-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bounceIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .labs-page {
        padding: 20px;
    }

    .lab-detail-container {
        gap: 15px;
    }

    .start-lab-btn,
    .check-progress-btn {
        padding: 14px;
        font-size: 14px;
    }

    .progress-popup {
        width: 90%;
        max-width: 500px;
        padding: 15px;
        font-size: 16px;
    }

    .lab-completed {
        padding: 15px;
        font-size: 14px;
    }
}
