/* General styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #e0e0e0, #ffffff); /* Subtle gradient */
  color: #333;
  width: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

/* Header styles */
.app-header {
  background-color: #2c3e50;
  color: white;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.app-header h1 {
  margin: 0;
  font-size: 2.5rem; /* Larger font size */
  font-weight: 700;
  letter-spacing: 1px; /* Slight spacing for modern look */
}

/* Main content styles */
.app-main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* Platform selection styles */
.platform-selection {
  background-color: white;
  border-radius: 12px; /* More rounded corners */
  padding: 2.5rem; /* Increased padding */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
  text-align: center;
}

.platform-selection h2 {
  margin-bottom: 2rem;
  color: #2c3e50;
  font-size: 1.75rem; /* Slightly larger font size */
  font-weight: 600; /* Bolder for emphasis */
}

.platform-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem; /* Increased gap */
}

.platform-btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem; /* Slightly larger font size */
  border: none;
  border-radius: 8px; /* More rounded corners */
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600; /* Bolder font weight */
}

.platform-btn:hover {
  transform: translateY(-4px); /* More pronounced lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow */
}

.aws-btn {
  background: linear-gradient(135deg, #ff9900, #ffb833); /* Gradient background */
  color: white;
}

.gcp-btn {
  background: linear-gradient(135deg, #4285f4, #357ae8); /* Gradient background */
  color: white;
}

.btn-icon {
  margin-right: 0.5rem;
  font-size: 1.3rem; /* Slightly larger icon */
}

/* Control panel styles */
.control-panel {
  background-color: white;
  border-radius: 12px; /* More rounded corners */
  padding: 2.5rem; /* Increased padding */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
  width: 100%;
  max-width: 850px; /* Adjusted max-width */
}

.user-info {
  margin-bottom: 2rem; /* Increased margin */
  text-align: center;
}

.user-info h2 {
  color: #2c3e50;
  font-size: 2rem; /* Larger font size */
  font-weight: 600; /* Bolder font weight */
}

/* Logout button styles */
.logout-btn {
  display: block;
  margin: 2rem auto 0;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem; /* Slightly larger font size */
  background: linear-gradient(135deg, #e74c3c, #c0392b); /* Gradient background */
  color: white;
  border: none;
  border-radius: 8px; /* More rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-btn:hover {
  background: linear-gradient(135deg, #c0392b, #a93226); /* Darker gradient on hover */
  transform: scale(1.05); /* Slightly larger scale */
}

/* Footer styles */
.app-footer {
  background-color: #34495e;
  color: white;
  text-align: center;
  padding: 1.5rem; /* Increased padding */
  font-size: 1rem; /* Slightly larger font size */
}

/* Responsive design */
@media (max-width: 768px) {
  .app-main {
    padding: 1rem;
  }

  .platform-buttons {
    flex-direction: column;
  }

  .platform-btn {
    width: 100%;
  }
}
.platform-btn.azure-btn {
  background-color: #0078d4; /* Azure blue */
  color: white;
}

.platform-btn.azure-btn .btn-icon {
  font-size: 1.5rem;
}
