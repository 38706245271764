/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #f5f7fa;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Report Page Styles */
.report-page {
  padding: 40px;
  background: linear-gradient(135deg, #ffffff 0%, #f7f9fc 100%);
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.report-page:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.report-page h1 {
  color: #2d2d2d;
  font-size: 40px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
  animation: fadeIn 1s ease-out;
}

.report-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.report-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.report-card h2 {
  margin-top: 0;
  color: #333;
  font-size: 28px;
  font-weight: 600;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  animation: fadeIn 1s ease-out;
}

.report-card p {
  margin: 12px 0;
  font-size: 16px;
  color: #555;
}

.progress-container {
  margin: 20px 0;
}

.progress-bar {
  width: 100%;
  background: linear-gradient(135deg, #e0e0e0 0%, #f5f5f5 100%);
  border-radius: 12px;
  overflow: hidden;
  height: 30px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}

.progress-bar-fill {
  height: 100%;
  background: linear-gradient(135deg, #4caf50 0%, #2c6b1f 100%);
  color: white;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  border-radius: 12px 0 0 12px;
  transition: width 0.4s ease;
}

.progress-text {
  font-size: 14px;
  color: #777;
  text-align: center;
  margin-top: 5px;
}

/* Back to Control Panel Button Styles */
.back-to-control-panel-btn {
  display: block;
  width: 100%;
  max-width: 220px;
  margin: 30px auto 0 auto;
  padding: 12px 24px;
  background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.back-to-control-panel-btn:hover {
  background: linear-gradient(135deg, #0056b3 0%, #003d7a 100%);
  transform: translateY(-2px);
}

.back-to-control-panel-btn:focus {
  outline: none;
}

/* Additional Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading-message {
  font-size: 18px;
  text-align: center;
  color: #555;
}
