/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  line-height: 1.6;
}

/* Labs Page Styles */
.labs-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

/* Add a decorative background element */
.labs-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 123, 255, 0.1), rgba(0, 123, 255, 0.05));
  z-index: -1;
  border-radius: 16px;
}

/* Title Styling */
.labs-page h2 {
  color: #007bff;
  text-align: center;
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 700;
}

/* Grid Container */
.labs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 0;
}

/* Lab Card Styling */
.lab-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lab-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(135deg, #007bff, #0056b3);
  transform: scaleY(0);
  transition: transform 0.3s;
}

.lab-card:hover::after {
  transform: scaleY(1);
}

.lab-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.lab-card h3 {
  margin-top: 0;
  font-size: 20px;
  color: #333;
}

/* Button Styling */
.view-details-btn {
  display: inline-block;
  margin-top: auto;
  padding: 12px 24px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
}

.view-details-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .labs-page {
      padding: 30px;
  }

  .labs-grid {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 15px;
  }

  .lab-card {
      padding: 15px;
  }

  .view-details-btn {
      padding: 10px 20px;
      font-size: 14px;
  }
}
/* Back to EC2 Control Panel Button */
.back-to-ec2-btn {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 16px;
  text-align: center;
}

.back-to-ec2-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}
