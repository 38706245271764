/* metrics.css */
.metrics-container {
    padding: 0;
    margin: 0;
    height: auto; /* Adjusted for content height */
    overflow: hidden;
    display: flex;
    flex-direction: column; /* Arrange content vertically */
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4; /* Optional: to give a background color to the container */
}

.metrics-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Increased minimum size for larger images */
    gap: 16px; /* Spacing between images */
    width: 100%;
    max-width: 1400px; /* Increased max-width for larger screens */
}

.metric-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align the content */
}

.metric-image {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    max-height: 450px; /* Increased max-height for larger images */
    object-fit: cover; /* Ensure images cover their container while maintaining aspect ratio */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.metric-title {
    margin-top: 12px; /* Space between image and title */
    font-size: 18px; /* Adjusted font size for better readability */
    font-weight: bold;
    text-align: center;
    color: #333; /* Adjust color as needed */
}

.back-button {
    margin-top: 20px; /* Space between images and button */
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #1A73E8;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}
