General Styles
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to right, #f4f4f4, #e0e0e0); /* Gradient background */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 20px;
  color: #333;
}

/* Control Panel Styles */
.control-panel {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px; /* Rounded corners for a softer look */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  border: 1px solid #ddd;
}

.panel-title {
  font-size: 32px; /* Larger title */
  margin-bottom: 20px;
  color: #222; /* Slightly darker color */
  font-weight: 700;
  text-align: center;
}

.control-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; /* Increased spacing for a cleaner look */
  width: 100%;
  max-width: 900px;
  margin-bottom: 20px;
}

.control-btn {
  background-color: #1A73E8;
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 15px 25px;
  border-radius: 12px; /* More rounded corners */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600; /* Slightly bolder font */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.control-btn:hover {
  background-color: #0056b3;
  transform: translateY(-4px); /* Slightly more pronounced effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.control-btn:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  cursor: not-allowed;
  transform: none;
}

/* Link Button */
.link-btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 15px 25px;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.link-btn:hover {
  background-color: #0056b3;
  text-decoration: none;
}

/* Output Section */
.output-block {
  margin-top: 20px;
  width: 90%;
}

.output {
  background-color: #000; /* Black background for the output block */
  color: #fff; /* White text color for contrast */
  border-radius: 12px; /* Rounded corners */
  margin-top: 20px;
  padding: 20px; /* Increased padding for better spacing */
  overflow: auto;
  max-height: 350px; /* Slightly increased max-height */
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Consolas, Monaco, 'Andale Mono', monospace;
  font-size: 16px; /* Slightly larger font size */
  text-align: left;
  width: 100%; /* Full width */
  max-width: 1200px; /* Increased max-width */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25); /* Enhanced shadow */
}

/* Info Section within Output Block */
.output-info {
  background-color: #1e1e1e; /* Dark gray background for info section */
  border: 1px solid #333; /* Slightly lighter border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Increased padding */
  margin-top: 20px;
  color: white;
}

/* IAM Credentials Styles */
.iam-credentials {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 15px; /* More rounded corners */
  padding: 30px; /* Increased padding */
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.iam-credentials h2 {
  margin-top: 0;
  color: #222; /* Slightly darker color */
  font-size: 28px; /* Larger font size */
  font-weight: 700;
  border-bottom: 3px solid #ddd; /* Thicker border for emphasis */
  padding-bottom: 12px; /* Increased padding */
}

.iam-credentials h4 {
  margin: 16px 0; /* Increased margin */
  font-size: 18px; /* Slightly larger font size */
}

.iam-credentials a {
  color: #007bff;
  text-decoration: none;
}

.iam-credentials a:hover {
  text-decoration: underline;
}

/* IAM Error Styles */
.iam-error {
  color: #dc3545;
  font-size: 18px; /* Slightly larger font size */
  margin-top: 20px;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.833); /* Darker overlay for contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-box {
  background-color: #ffffff; /* White background for the box */
  color: white;
  border-radius: 12px; /* Rounded corners */
  padding: 40px; /* Padding around the spinner */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #1A73E8; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* Keyframes for Spinner Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Updated loading message styles */
.loading-message {
  color: white; /* Ensure the text color is white */
  font-size: 18px; /* Adjust font size if needed */
  font-weight: bold; /* Optional: make the text bold */
}


/* Keyframes for Spinner Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .control-buttons {
    grid-template-columns: 1fr;
    gap: 15px; /* Adjusted gap for smaller screens */
  }

  .control-btn {
    width: 100%;
    margin: 5px 0;
    font-size: 16px;
    padding: 12px 20px;
  }

  .output {
    max-height: 200px; /* Adjusted max-height */
  }
}

/* Default styling for active links */
.link-btn {
  text-decoration: none;
  cursor: pointer;
}

/* Disabled link styling */
.disabled-link {
  background-color: gray;
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}
/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 15px; /* Match the rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  width: 90%;
  max-width: 500px; /* Set max-width for better usability */
  text-align: center;
}

/* Platform Buttons in Modal */
.platform-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 15px; /* Space between buttons */
  margin-top: 20px; /* Space above buttons */
}

.platform-buttons button {
  background-color: #1A73E8; /* Match the main button color */
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 15px 25px;
  border-radius: 12px; /* Match main button corners */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  font-size: 18px; /* Match font size */
  font-weight: 600; /* Match font weight */
}

.platform-buttons button:hover {
  background-color: #0056b3; /* Darken on hover */
  transform: translateY(-2px); /* Subtle lift effect */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.platform-buttons button:disabled {
  background-color: #d3d3d3; /* Disabled state */
  color: #a9a9a9;
  cursor: not-allowed;
}

/* Close Button */
.close-button {
  background-color: #ff4757; /* Red for close */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 20px; /* Space above the button */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #e84118; /* Darker red on hover */
}




